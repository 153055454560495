// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/admin';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  root: '/',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  // payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  page403: '/403',
  loanApplication: '/loan-application',
  products: '/our-products',
  components: '/components',
  borrowerPayment: '/borrower-payment',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    configuration: path(ROOTS_DASHBOARD, '/configuration/edit'),
    branchConfiguration: path(ROOTS_DASHBOARD, '/branch-configuration/edit'),
    smsConfiguration: path(ROOTS_DASHBOARD, '/sms-configuration/edit'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  branch: {
    root: path(ROOTS_DASHBOARD, '/branch'),
    list: path(ROOTS_DASHBOARD, '/branch/list'),
    new: path(ROOTS_DASHBOARD, '/branch/new'),
    edit: (name) => path(ROOTS_DASHBOARD, `/branch/${name}/edit`),
  },
  role: {
    root: path(ROOTS_DASHBOARD, '/role'),
    list: path(ROOTS_DASHBOARD, '/role/list'),
    new: path(ROOTS_DASHBOARD, '/role/new'),
    edit: (name) => path(ROOTS_DASHBOARD, `/role/${name}/edit`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/21/edit`),
  },
  agent: {
    root: path(ROOTS_DASHBOARD, '/agent'),
    new: path(ROOTS_DASHBOARD, '/agent/new'),
    list: path(ROOTS_DASHBOARD, '/agent/list'),
    edit: (id) => path(ROOTS_DASHBOARD, `/agent/${id}/edit`),
  },
  loan: {
    root: path(ROOTS_DASHBOARD, '/loan'),
    list: path(ROOTS_DASHBOARD, '/loan/list'),
    new: path(ROOTS_DASHBOARD, '/loan/new'),
    preFiteredList: (productType, status, doRefresh = false) =>
      path(ROOTS_DASHBOARD, `/loan/list?productType=${productType}&status=${status}&refresh=${doRefresh}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/loan/${id}/edit`),
    postApprovalEdit: (id) => path(ROOTS_DASHBOARD, `/loan/${id}/post-approval-edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/loan/${id}`),
    lar: (id) => path(ROOTS_DASHBOARD, `/loan/${id}/LAR`),
    printApplication: (id) => path(ROOTS_DASHBOARD, `/loan/${id}/print-application-form`),
    loanPayments: (id) => path(ROOTS_DASHBOARD, `/loan/${id}/loan-payments`),
    loanStatement: (id) => path(ROOTS_DASHBOARD, `/loan/${id}/loan-statement-of-account`),
  },
  borrower: {
    root: path(ROOTS_DASHBOARD, '/borrower'),
    list: path(ROOTS_DASHBOARD, '/borrower/list'),
    edit: (id) => path(ROOTS_DASHBOARD, `/borrower/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/borrower/${id}`),
    loanHistory: (id, loanId) => path(ROOTS_DASHBOARD, `/borrower/${id}/loan-history/${loanId}`),
    addLoan: (id, loanId) => path(ROOTS_DASHBOARD, `/borrower/${id}/loans/${loanId}/additional-loan`),
    specialLoan: (id, loanId) => path(ROOTS_DASHBOARD, `/borrower/${id}/loans/${loanId}/special-loan`),
    renewLoan: (id, loanId, contractId) =>
      path(ROOTS_DASHBOARD, `/borrower/${id}/loans/${loanId}/contracts/${contractId}/renew-loan`),
    editLoanDetails: (id, loanId) => path(ROOTS_DASHBOARD, `/borrower/${id}/loans/${loanId}/edit-loan-details`),
  },

  collection: {
    root: path(ROOTS_DASHBOARD, '/collection'),
    atmList: path(ROOTS_DASHBOARD, '/collection/atm-list'),
    otherList: path(ROOTS_DASHBOARD, '/collection/other-list'),
    clustersCollectionList: path(ROOTS_DASHBOARD, '/collection/clusters-collection-list'),
    otherCollectionList: path(ROOTS_DASHBOARD, '/collection/other-loans-collection-list'),
    gcashCollectionList: path(ROOTS_DASHBOARD, '/collection/gcash-collection-list'),
  },

  reports: {
    root: path(ROOTS_DASHBOARD, '/reports/atm-daily-collection'),
    loanReleases: path(ROOTS_DASHBOARD, '/reports/loan-releases'),
    collection: path(ROOTS_DASHBOARD, '/reports/collection'),
    pastDueAccounts: path(ROOTS_DASHBOARD, '/reports/past-due-accounts'),
    fullyPaidPastDueAccounts: path(ROOTS_DASHBOARD, '/reports/fully-paid-past-due-accounts'),
    loansReceivable: path(ROOTS_DASHBOARD, '/reports/loans-receivable-monitoring'),
    loanInterest: path(ROOTS_DASHBOARD, '/reports/loan-interest-monitoring'),
    claimedChangeMonitoring: path(ROOTS_DASHBOARD, '/reports/claimed-change-monitoring'),
    unclaimedChangeMonitoring: path(ROOTS_DASHBOARD, '/reports/unclaimed-change-monitoring'),
    apirAndAcopSchedule: path(ROOTS_DASHBOARD, '/reports/apir-and-acop-schedule'),
    closedAndDroppedOuts: path(ROOTS_DASHBOARD, '/reports/closed-and-dropped-outs'),
    dailySummaryReport: path(ROOTS_DASHBOARD, '/reports/daily-summary-report'),
    collectionEfficiencyRate: path(ROOTS_DASHBOARD, '/reports/efficiency-report'),
    creditInvestigationQualityRate: path(ROOTS_DASHBOARD, '/reports/credit-investigation-quality-rate'),
  },

  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
