import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/admin';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/admin')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard', element: <MainDashboard /> },
        { path: 'configuration/edit', element: <GeneralConfiguration /> },
        { path: 'branch-configuration/edit', element: <GeneralBranchConfiguration /> },
        { path: 'sms-configuration/edit', element: <GeneralSMSConfiguration /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },
        { path: 'app', element: <GeneralApp /> },

        {
          path: 'branch',
          children: [
            { element: <Navigate to="/admin/management/branch/list" replace />, index: true },
            { path: 'list', element: <BranchList /> },
            { path: 'new', element: <BranchCreate /> },
            { path: ':name/edit', element: <BranchCreate /> },
          ],
        },

        {
          path: 'role',
          children: [
            { element: <Navigate to="/admin/role/list" replace />, index: true },
            { path: 'list', element: <RoleList /> },
            { path: 'new', element: <RoleCreate /> },
            { path: ':name/edit', element: <RoleCreate /> },
          ],
        },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/admin/user/list" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },

        {
          path: 'agent',
          children: [
            { element: <Navigate to="/admin/agent/list" replace />, index: true },
            { path: 'list', element: <AgentList /> },
            { path: 'new', element: <AgentCreate /> },
            { path: ':id/edit', element: <AgentCreate /> },
          ],
        },

        {
          path: 'loan',
          children: [
            { element: <Navigate to="/admin/loan/list" replace />, index: true },
            { path: 'list', element: <LoanList /> },
            { path: 'new', element: <LoanApplication /> },
            { path: ':id/edit', element: <LoanEditApplication /> },
            { path: ':id/post-approval-edit', element: <ApprovedLoanEditApplication /> },
            { path: ':id', element: <LoanDetails /> },
            { path: ':id/LAR', element: <LoanLAR /> },
            { path: ':id/print-application-form', element: <LoanPrintApplication /> },
            { path: ':id/loan-payments', element: <LoanPaymentsList /> },
            { path: ':id/loan-statement-of-account', element: <LoanStatementOfAccount /> },
          ],
        },

        {
          path: 'borrower',
          children: [
            { element: <Navigate to="/admin/borrower/list" replace />, index: true },
            { path: 'list', element: <BorrowerList /> },
            { path: ':id/edit', element: <BorrowerEditDetails /> },
            { path: ':id', element: <BorrowerDetails /> },
            { path: ':id/loan-history/:loanId', element: <BorrowerLoanHistory /> },
            { path: ':id/loans/:loanId/additional-loan', element: <LoanAdditional /> },
            { path: ':id/loans/:loanId/special-loan', element: <LoanSpecial /> },
            { path: ':id/loans/:loanId/contracts/:contractId/renew-loan', element: <LoanRenewal /> },
            { path: ':id/loans/:loanId/edit-loan-details', element: <LoanEditDetails /> },
          ],
        },

        {
          path: 'collection',
          children: [
            { element: <Navigate to="/admin/collection/atm-list" replace />, index: true },
            { path: 'atm-list', element: <ATMCollectionList /> },
            { path: 'other-list', element: <OtherCollectionList /> },
            { path: 'clusters-collection-list', element: <ClustersCollectionList /> },
            { path: 'other-loans-collection-list', element: <OtherLoansCollectionList /> },
            { path: 'gcash-collection-list', element: <GCashCollectionList /> },
          ],
        },

        {
          path: 'reports',
          children: [
            { element: <Navigate to="/admin/reports/atm-daily-collection" replace />, index: true },
            { path: 'atm-daily-collection', element: <ATMDailyCollection /> },
            { path: 'loan-releases', element: <LoanReleases /> },
            { path: 'collection', element: <CollectionReport /> },
            { path: 'past-due-accounts', element: <PastDueAccounts /> },
            { path: 'fully-paid-past-due-accounts', element: <FullyPaidPastDueAccounts /> },
            { path: 'loans-receivable-monitoring', element: <LoansReceivable /> },
            { path: 'loan-interest-monitoring', element: <LoanInterest /> },
            { path: 'claimed-change-monitoring', element: <ClaimedChangeMonitoring /> },
            { path: 'unclaimed-change-monitoring', element: <UnclaimedChangeMonitoring /> },
            { path: 'apir-and-acop-schedule', element: <ApirAndAcopSchedule /> },
            { path: 'closed-and-dropped-outs', element: <ClosedAndDroppedOuts /> },
            { path: 'daily-summary-report', element: <DailySummaryReport /> },
            { path: 'efficiency-report', element: <CollectionEfficiencyRate /> },
            { path: 'credit-investigation-quality-rate', element: <CreditInvestigationQualityRate /> },
          ],
        },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/admin/e-commerce/shop" replace />, index: false },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/admin/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/admin/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/admin/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '403', element: <Forbidden /> },
        { path: '*', element: <Navigate to="/404" replace /> },
        { path: 'borrower-payment', element: <BorrowerPayment /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'loan-application', element: <LoanApplicationForm /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'our-products', element: <Products /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const MainDashboard = Loadable(lazy(() => import('../pages/admin/general/MainDashboard')));
const GeneralConfiguration = Loadable(lazy(() => import('../pages/admin/general/GeneralConfiguration')));
const GeneralBranchConfiguration = Loadable(lazy(() => import('../pages/admin/general/GeneralBranchConfiguration')));
const GeneralSMSConfiguration = Loadable(lazy(() => import('../pages/admin/general/GeneralSMS')));
const GeneralApp = Loadable(lazy(() => import('../pages/admin/general/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/admin/general/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/admin/general/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/admin/general/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/admin/general/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/admin/e-commerce/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/admin/e-commerce/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/admin/e-commerce/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/admin/e-commerce/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/admin/e-commerce/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/admin/invoice/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/admin/invoice/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/admin/invoice/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/admin/invoice/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/admin/blog/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/admin/blog/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/admin/blog/BlogNewPost')));

// BRANCH
const BranchList = Loadable(lazy(() => import('../pages/admin/branch/BranchList')));
const BranchCreate = Loadable(lazy(() => import('../pages/admin/branch/BranchCreate')));

// ROLE
const RoleList = Loadable(lazy(() => import('../pages/admin/role/RoleList')));
const RoleCreate = Loadable(lazy(() => import('../pages/admin/role/RoleCreate')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/admin/user/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/admin/user/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/admin/user/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/admin/user/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/admin/user/UserCreate')));

// AGENT
const AgentList = Loadable(lazy(() => import('../pages/admin/agent/AgentList')));
const AgentCreate = Loadable(lazy(() => import('../pages/admin/agent/AgentCreate')));

// LOAN
const LoanList = Loadable(lazy(() => import('../pages/admin/loan/LoanList')));
const LoanApplication = Loadable(lazy(() => import('../pages/admin/loan/LoanApplication')));
const LoanEditApplication = Loadable(lazy(() => import('../pages/admin/loan/LoanEditApplication')));
const ApprovedLoanEditApplication = Loadable(lazy(() => import('../pages/admin/loan/ApprovedLoanEditApplication')));
const LoanDetails = Loadable(lazy(() => import('../pages/admin/loan/LoanDetails')));
const LoanLAR = Loadable(lazy(() => import('../pages/admin/loan/LoanLAR')));
const LoanAdditional = Loadable(lazy(() => import('../pages/admin/loan/LoanAdditional')));
const LoanSpecial = Loadable(lazy(() => import('../pages/admin/loan/LoanSpecial')));
const LoanRenewal = Loadable(lazy(() => import('../pages/admin/loan/LoanRenewal')));
const LoanPrintApplication = Loadable(lazy(() => import('../pages/admin/loan/LoanPrintApplication')));
const LoanPaymentsList = Loadable(lazy(() => import('../pages/admin/loan/LoanPaymentsList')));
const LoanEditDetails = Loadable(lazy(() => import('../pages/admin/loan/LoanEditDetails')));
const LoanStatementOfAccount = Loadable(lazy(() => import('../pages/admin/loan/LoanStatementOfAccount')));

// BORROWER
const BorrowerList = Loadable(lazy(() => import('../pages/admin/borrower/BorrowerList')));
const BorrowerEditDetails = Loadable(lazy(() => import('../pages/admin/borrower/BorrowerEditDetails')));
const BorrowerDetails = Loadable(lazy(() => import('../pages/admin/borrower/BorrowerDetails')));
const BorrowerLoanHistory = Loadable(lazy(() => import('../pages/admin/borrower/BorrowerLoanHistory')));

// COLLECTION
const ATMCollectionList = Loadable(lazy(() => import('../pages/admin/collection/atm-loan/CollectionList')));
const OtherCollectionList = Loadable(lazy(() => import('../pages/admin/collection/other-loan/CollectionList')));
const ClustersCollectionList = Loadable(
  lazy(() => import('../pages/admin/collection/clusters-for-collection/CollectionList'))
);
const OtherLoansCollectionList = Loadable(
  lazy(() => import('../pages/admin/collection/other-loans-for-collection/OtherCollectionList'))
);
const GCashCollectionList = Loadable(lazy(() => import('../pages/admin/collection/gcash/CollectionList')));

// REPORTS
const ATMDailyCollection = Loadable(
  lazy(() => import('../pages/admin/reports/atm-daily-collection/ATMDailyCollectionTable'))
);
const LoanReleases = Loadable(lazy(() => import('../pages/admin/reports/loan-releases/LoanReleasesTable')));
const CollectionReport = Loadable(lazy(() => import('../pages/admin/reports/collection/CollectionTable')));
const PastDueAccounts = Loadable(lazy(() => import('../pages/admin/reports/past-due-accounts/PastDueAccountsTable')));
const FullyPaidPastDueAccounts = Loadable(
  lazy(() => import('../pages/admin/reports/fully-paid-past-due-accounts/FullyPaidPastDueAccountsTable'))
);
const LoansReceivable = Loadable(
  lazy(() => import('../pages/admin/reports/loans-receivable-monitoring/LoansReceivableMonitoringTable'))
);
const LoanInterest = Loadable(
  lazy(() => import('../pages/admin/reports/loan-interest-monitoring/LoanInterestMonitoringTable'))
);
const ClaimedChangeMonitoring = Loadable(
  lazy(() => import('../pages/admin/reports/claimed-change/ClaimedChangeTable'))
);
const UnclaimedChangeMonitoring = Loadable(
  lazy(() => import('../pages/admin/reports/unclaimed-change/UnclaimedChangeTable'))
);
const ApirAndAcopSchedule = Loadable(
  lazy(() => import('../pages/admin/reports/apir-and-acop-schedule/ApirAndAcopSchedTable'))
);
const ClosedAndDroppedOuts = Loadable(
  lazy(() => import('../pages/admin/reports/closed-and-dropped-outs/ClosedAndDroppedOutsTable'))
);
const DailySummaryReport = Loadable(
  lazy(() => import('../pages/admin/reports/daily-summary-report/DailySummaryReportTable'))
);
const CollectionEfficiencyRate = Loadable(
  lazy(() => import('../pages/admin/reports/collection-efficiency-rate/CollectionEfficiencyRateTable'))
);
const CreditInvestigationQualityRate = Loadable(
  lazy(() => import('../pages/admin/reports/credit-investigation-quality-rate/CreditInvestigationQualityRateTable'))
);

// APP
const Chat = Loadable(lazy(() => import('../pages/admin/Chat')));
const Mail = Loadable(lazy(() => import('../pages/admin/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/admin/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/admin/Kanban')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/main/Home')));
const About = Loadable(lazy(() => import('../pages/main/About')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/main/PrivacyPolicy')));
const Products = Loadable(lazy(() => import('../pages/main/Products')));
const Contact = Loadable(lazy(() => import('../pages/main/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/main/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/main/ComingSoon')));
const LoanApplicationForm = Loadable(lazy(() => import('../pages/main/LoanApplication')));
const Maintenance = Loadable(lazy(() => import('../pages/main/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/main/Pricing')));
// const Payment = Loadable(lazy(() => import('../pages/main/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/main/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/main/Page404')));
const Forbidden = Loadable(lazy(() => import('../pages/main/Page403')));
const BorrowerPayment = Loadable(lazy(() => import('../pages/main/BorrowerPayment')));
