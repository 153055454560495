import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import axios from '../utils/axios';
import { HOST_API_KEY } from '../config';
import { isValidToken, setSession } from '../utils/jwt';
import { dispatch as rootDispatcher } from '../redux/store';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isRootUser: false,
  isLoanApprover: false,
  isDIOfficer: false,
  isAssessor: false,
  isCIOfficer: false,
  isCollector: false,
  permissions: [],
  activeBranch: null,
  account: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, account } = action.payload;
    axios.defaults.headers.common['X-Branch-Id'] = account?.branchDetails?.id;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isRootUser: account?.superUser,
      isLoanApprover: account?.authorities?.includes('APPROVE_LOAN'),
      isDIOfficer: account?.authorities?.includes('DISBURSE_LOAN'),
      isAssessor: account?.authorities?.includes('LOAN_ITEM_ASSESS'),
      isCIOfficer: account?.authorities?.includes('CI_LOAN_APPLICATION'),
      isCollector: account?.authorities?.includes('COLLECT_PAYMENT'),
      permissions: account?.authorities,
      activeBranch: account?.branchDetails?.id,
      account,
    };
  },
  LOGIN: (state, action) => {
    const { account } = action.payload;
    axios.defaults.headers.common['X-Branch-Id'] = account?.branchDetails?.id;
    return {
      ...state,
      isAuthenticated: true,
      isRootUser: account?.superUser,
      isLoanApprover: account?.authorities?.includes('APPROVE_LOAN'),
      isDIOfficer: account?.authorities?.includes('DISBURSE_LOAN'),
      isAssessor: account?.authorities?.includes('LOAN_ITEM_ASSESS'),
      isCIOfficer: account?.authorities?.includes('CI_LOAN_APPLICATION'),
      isCollector: account?.authorities?.includes('COLLECT_PAYMENT'),
      permissions: account?.authorities,
      activeBranch: account?.branchDetails?.id,
      account,
    };
  },
  UPDATE_ACCOUNT_PHOTO: (state, action) => {
    const { photo } = action.payload;
    return {
      ...state,
      account: { ...state.account, photo },
    };
  },
  UPDATE_ACCOUNT_PERSONAL_DETAILS: (state, action) => {
    const { personalDetails: details } = action.payload;
    return {
      ...state,
      account: { ...state.account, personalDetails: details },
    };
  },
  SET_ACTIVE_BRANCH: (state, action) => {
    axios.defaults.headers.common['X-Branch-Id'] = action.payload;
    return {
      ...state,
      activeBranch: action.payload,
    };
  },
  LOGOUT: (state) => {
    rootDispatcher({ type: 'LOGOUT' });
    return {
      ...state,
      isAuthenticated: false,
      isRootUser: false,
      account: null,
      isLoanApprover: null,
      isDIOfficer: null,
      isAssessor: null,
      isCIOfficer: null,
      isCollector: null,
      permissions: null,
      activeBranch: null,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  updatePhoto: () => Promise.resolve(),
  updatePersonalDetails: () => Promise.resolve(),
  setActiveBranch: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          const decodedToken = setSession(accessToken);
          const { authorities } = decodedToken;
          const response = await axios.get(`/account?timestamp=${new Date().getTime()}`);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              account: { ...response.data, username: decodedToken.user_name, authorities },
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              account: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            account: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (username, password, rememberMe) => {
    const loginResponse = await axios.post(
      `/oauth/token?username=${username}&password=${password}&grant_type=password&scope=rw&timestamp=${new Date().getTime()}`,
      {},
      { headers: { Authorization: `Basic ${HOST_API_KEY}` } }
    );
    const decodedToken = setSession(loginResponse.data.access_token);
    const { authorities } = decodedToken;

    const fetchAccountResponse = await axios.get(`/account?timestamp=${new Date().getTime()}`);

    if (rememberMe) {
      localStorage.setItem('login.username', username);
    } else {
      localStorage.removeItem('login.username');
    }
    localStorage.setItem('login.rememberMe', rememberMe);

    dispatch({
      type: 'LOGIN',
      payload: {
        account: { username: decodedToken.user_name, ...fetchAccountResponse.data, authorities },
      },
    });
  };

  const postPhoto = async (file, isNew) => {
    const formData = new FormData();
    formData.append('file', file);
    let response;
    if (isNew) {
      response = await axios.post('/account/photo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } else {
      response = await axios.patch('/account/photo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }

    dispatch({
      type: 'UPDATE_ACCOUNT_PHOTO',
      payload: {
        photo: response.data,
      },
    });
  };

  const updatePersonalDetails = async (updatedDetails) => {
    let dob = updatedDetails.dob instanceof Date ? format(updatedDetails.dob, 'MM/dd/yyyy') : updatedDetails.dob;
    if (dob === '') {
      dob = null;
    }
    const data = {
      personalDetails: {
        firstName: updatedDetails.firstName,
        middleName: updatedDetails.middleName,
        lastName: updatedDetails.lastName,
        dateOfBirth: dob,
        civilStatus: updatedDetails.civilStatus,
        gender: updatedDetails.gender,
        addresses: [{ addressLine: updatedDetails.address, addressType: 'PRESENT' }],
        contactInformations: [
          {
            type: 'MOBILE_NO',
            subType: 'PRIMARY',
            value: updatedDetails.phoneNumber,
          },
          {
            type: 'EMAIL',
            subType: 'PRIMARY',
            value: updatedDetails.email,
          },
        ],
      },
    };
    await axios.patch('/account', data);
    dispatch({
      type: 'UPDATE_ACCOUNT_PERSONAL_DETAILS',
      payload: {
        ...data,
      },
    });
  };

  const setActiveBranch = async (branchId) => {
    dispatch({ type: 'SET_ACTIVE_BRANCH', payload: branchId });
  };

  const logout = async () => {
    try {
      await axios.post('/oauth/logout', {});
    } finally {
      setSession(null);
      dispatch({ type: 'LOGOUT' });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        postPhoto,
        updatePersonalDetails,
        setActiveBranch,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
