import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isInitializing: false,
  isLoading: false,
  error: null,
  orcrRateConfigurations: [],
};

const slice = createSlice({
  name: 'orcr-rate-configurations',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      if (!state.orcrRateConfigurations || state.orcrRateConfigurations.length === 0) {
        state.isInitializing = true;
      }
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isInitializing = false;
    },

    getORCRRateConfigurationsSuccess(state, action) {
      state.isLoading = false;
      state.orcrRateConfigurations = action.payload;
      state.isInitializing = false;
    },

    createORCRRateConfigurationSuccess(state, action) {
      state.isLoading = false;
      state.orcrRateConfigurations.push({
        ...action.payload
      });
    },

    updateORCRRateConfigurationSuccess(state, action) {
      state.isLoading = false;
      const index = state.orcrRateConfigurations.findIndex(
        (orcrRateConfiguration) => orcrRateConfiguration.id === action.payload.id
      );
      let updatedORCRRateConfiguration = state.orcrRateConfigurations[index];
      updatedORCRRateConfiguration = {
        ...updatedORCRRateConfiguration,
        ...action.payload
      };
      state.orcrRateConfigurations[index] = updatedORCRRateConfiguration;
    },

    deleteORCRRateConfigurationSuccess(state, action) {
      state.isLoading = false;
      state.orcrRateConfigurations = state.orcrRateConfigurations.filter(
        (orcrRateConfiguration) => orcrRateConfiguration.id !== action.payload
      );
    },
  },
});

export const {
  startLoading,
  hasError,
  getORCRRateConfigurationsSuccess,
  createORCRRateConfigurationSuccess,
  updateORCRRateConfigurationSuccess,
  deleteORCRRateConfigurationSuccess,
} = slice.actions;

export default slice.reducer;
